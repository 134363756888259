import enUS from '../../public/locales/en-US.json'

type TranslationsType = Record<string, string>
type LocaleConfig = {
  currentLocale: string
  locales: Record<string, TranslationsType>
}

let currentConfig: LocaleConfig = {
  currentLocale: 'en-US',
  locales: { 'en-US': enUS },
}

export function get(
  key: string,
  variables?: Record<string, string | number | undefined>
): string {
  const translations = currentConfig.locales[currentConfig.currentLocale]
  let text = translations[key]

  // Return key if translation doesn't exist, but return empty string if translation exists and is empty
  if (text === undefined) {
    return key
  }

  if (variables) {
    Object.entries(variables).forEach(([varKey, value]) => {
      // Use replaceAll to replace all occurrences of the variable
      const regex = new RegExp(`\\{${varKey}\\}`, 'g')
      text = text.replace(regex, String(value || ''))
    })
  }

  return text
}

export function determineLocale(): string {
  // For now, we'll just return en-US as it's our only supported locale
  return 'en-US'
}

export function init(config: LocaleConfig): void {
  currentConfig = config
}

export function getInitOptions(): LocaleConfig {
  return currentConfig
}

export default {
  get,
  determineLocale,
  init,
  getInitOptions,
}
